<template lang="pug">
  BAlert.warning(
    :class="{ 'max_180': isNicolasEnabled }"
    show
    variant="warning"
    :title="title"
  )
    | {{ title }}
</template>

<script>
  import { BAlert } from "bootstrap-vue"
  import { mapGetters } from "vuex"

  export default {
    components: {
      BAlert
    },

    computed: {
      ...mapGetters(["isNicolasEnabled"]),

      title({ isNicolasEnabled }) {
        const key = isNicolasEnabled ? "modal_notice_180" : "modal_notice"

        return this.$t(`inventory_management.inventory_long_period.${key}`)
      }
    }
  }
</script>

<style lang="sass" scoped>
  .warning
    font-size: 0.8rem !important
    height: 45px
    margin-bottom: 25px
    text-align: center
  .max_180
    height: unset
</style>
